import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocationStrategy } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { EventsService } from '../../services/events.service';
import { CampaignService } from 'src/app/services/campaign.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
  code: any;
  codePath: any;
  campaign: any;
  email: string;
  wonAnalysis = false;
  wonVideoTitles = [];
  wonBrands = [];
  wonMonths = 0;

  with_app_session_block = true;

  constructor(private router: Router, private locationStrategy: LocationStrategy, private userService: UserService,
    // tslint:disable-next-line: max-line-length
    private spinner: NgxSpinnerService, private translate: TranslateService, private eventsService: EventsService, private campaignService: CampaignService,) { }

  ngOnInit(): void {
    this.eventsService.langString.subscribe(langNow => this.translate.use(langNow));
    this.eventsService.campaignCode.subscribe(codeRequest => this.codePath = codeRequest);
    console.log(this.codePath);
    if (this.codePath === 0) {
      this.codePath = '';
    }
    this.campaignService.getCampaignUrl(this.codePath).then((response: any) => {
        console.log(response.coverImageUrl);
        this.campaign = response;
        this.userService.getUser().then(user => {
          console.log(user);
          this.email = user.email;
        }).catch(error => {
          console.log(error);
          this.spinner.hide();
        });
        this.spinner.hide();
      }).catch(error => {
       console.log(error);
       this.spinner.hide();
    });

    if (!this.with_app_session_block) {
      let state = window.history.state;
      this.wonAnalysis = !!state['analysis'];
      this.wonVideoTitles = (state['videos'] || []).map(e => e['title']);
      this.wonBrands = state['brands'] || [];
      this.wonMonths = state['duration'] || 0;
    } else {
      let state = window.history.state;
      this.wonBrands = state['brands'] || [];
      this.wonVideoTitles = (state['videos'] || []).map(e => e['title']);
      this.wonMonths = state['duration'] || 0;
    }
  }


  goBackHome() {
    this.spinner.show();
    this.eventsService.langString.subscribe(langNow => this.translate.use(langNow));
    this.router.navigate([this.codePath + '/landing-page']);
  }
}
