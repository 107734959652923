import { Component, OnInit } from '@angular/core';
import { CampaignService } from 'src/app/services/campaign.service';
import { LocationStrategy } from '@angular/common';
import { Campaign } from '../../models/campaign';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {
  code: any;
  campaign: Campaign;
  constructor(private campaignService: CampaignService,
              private spinner: NgxSpinnerService,
              private locationStrategy: LocationStrategy,
              private router: Router,
              private route: ActivatedRoute,
              private eventsService: EventsService) { }

  ngOnInit(): void {

     this.route.paramMap.subscribe(params => {
      this.code = params.get('code');
      //console.log('BaseComponent route: ' + this.code);
      if (this.code === 'landing-page') {
        this.code = '';
      }
      this.eventsService.setCampaignCode(this.code);
      this.router.navigate([this.code + '/landing-page']);
    });
  }

}
