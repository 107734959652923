import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CouponService } from '../../services/coupon.service';
import { Utils } from '../../services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocationStrategy } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { EventsService } from '../../services/events.service';
import { CampaignService } from 'src/app/services/campaign.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-assignment',
  templateUrl: './assignment.component.html',
  styleUrls: ['./assignment.component.scss']
})
export class AssignmentComponent implements OnInit {
  // tslint:disable-next-line: max-line-length
  constructor(private router: Router, private utils: Utils,  private translate: TranslateService, private locationStrategy: LocationStrategy,
              private spinner: NgxSpinnerService, private route: ActivatedRoute, private campaignService: CampaignService,
              private couponService: CouponService, private eventsService: EventsService) { }
  codePath: any;
  code: string;

  campaign: any;
  data: {};
  langUsed: any;
  clickCheckbox: boolean;
  codeError: boolean;
  checkboxError: boolean;
  captchaError: boolean;
  link_it = 'https://elitesrl.zendesk.com/hc/it/articles/360013874379-Come-trovare-il-seriale-del-Rullo';
  link_en = 'https://elitesrl.zendesk.com/hc/en-us/articles/360013874379-How-to-find-the-trainer-serial-number';
  token: string;

  withOperationCaptcha = true;
  operands = [0, 0, 0];
  joinButtonDisabled = true;
  operationResult: number;

  ngOnInit(): void {
    this.spinner.show();
    this.clickCheckbox = false;
    this.checkboxError = false;
    this.eventsService.langString.subscribe(langNow => {
      this.translate.use(langNow);
      this.langUsed = langNow;
      this.loadCampaignUrl();
    });

    this.operands[0] = Math.floor(Math.random() * 9 + 1);
    this.operands[1] = Math.floor(Math.random() * 4 + 2);
    this.operands[2] = Math.floor(Math.random() * 3 + 2);

    const grecaptcha = window['grecaptcha'];
    const owner = this;
    
    function initializeCaptcha() {
      setTimeout(() => {
        const key = environment.googleSiteKey;
          if (document.getElementById('captcha') != null) {
            grecaptcha.enterprise.render('captcha', {
              sitekey: key,
              action: 'assignCoupon',
              callback: (token: string) => {
                owner.onCaptchaCallback(token);
              }
            });
          } else {
            // retries optimistically later
            initializeCaptcha();
          }
      }, 10);
    }
    grecaptcha.enterprise.ready(() => {
      initializeCaptcha();
    });

    if (this.withOperationCaptcha) {
      this.joinButtonDisabled = true;
    } else {
      this.joinButtonDisabled = false;
    }

    if (localStorage.getItem('token') === null) {
      this.router.navigate(['/login']);
    }
  }

  loadCampaignUrl() {
    this.eventsService.campaignCode.subscribe(codeRequest => this.codePath = codeRequest);
    if (this.codePath === 0) {
      this.codePath = '';
    }
    this.campaignService.getCampaignUrl(this.codePath).then((response: any) => {
        this.campaign = response;
        this.spinner.hide();
      }).catch(error => {
       console.log(error);
       this.spinner.hide();
    });
  }

  checkSerial() {
    this.checkboxError = false;
    this.codeError = false;
    this.captchaError = false;
    if (!this.clickCheckbox) {
      this.checkboxError = true;
    }
    if (!this.code || this.code.length == 0) {
      this.codeError = true;
    }
    if (!this.token || this.token.length == 0) {
      this.captchaError = true;
    }
    if (this.codeError || this.checkboxError || this.captchaError) {
      return;
    }

    this.eventsService.langString.subscribe(langNow => {
      this.translate.use(langNow);
      this.langUsed = langNow;
    });
    this.spinner.show();
    this.campaignService.checkSerial(this.campaign.id, this.code.toUpperCase())
      .then((response: any) => {
        if (response.find === true) {
          this.data = {
            serialCode: this.code.toUpperCase(),
            trainerCode: response.trainerCode,
            lang: this.langUsed,
            token: this.token
          };
          // fa associazione
          this.associate();
        } else {
         // NON fa associazione, deve scegliere il rullo
          this.campaignService.setSerial(this.code.toUpperCase());
          this.eventsService.langString.subscribe(langNow => this.translate.use(langNow));
          this.router.navigate([this.codePath + '/assignment-second']);
        }
      }).catch(response => {
        //console.log(response);
        this.eventsService.langString.subscribe(langNow => this.translate.use(langNow));
        let error = response['error'];
        this.router.navigate([this.codePath + '/fail'], {queryParams: {error: error['code']}});
      });
  }

  associate() {
    this.spinner.show();
    this.couponService.postCoupon(this.campaign.id, this.data).then((response: any) => {
      //this.checkReminderElite();
      this.eventsService.langString.subscribe(langNow => this.translate.use(langNow));
      this.router.navigate([this.codePath + '/success'], {state: response});
    }).catch(response => {
      //console.log(response);
      this.eventsService.langString.subscribe(langNow => this.translate.use(langNow));
      let error = response['error'];
      this.router.navigate([this.codePath + '/fail'], {queryParams: {error: error['code']}});
    });
  }

  checkReminderElite() {
    this.couponService.getCoupons(this.campaign.id).then((response: any) => {
      console.log('ok');
    }).catch(error => {
      console.log('no');
      console.log(error);
    });
  }

  onCheckboxClicked() {
    if (this.clickCheckbox === false) {
      this.clickCheckbox = true;
    } else {
      this.clickCheckbox = false;
    }
  }

  onCaptchaCallback(token) {
    this.token = token;
  }

  operationChanged() {
    const providedResult = this.operationResult;
    const expectedResult = this.operands[0] + this.operands[1] * this.operands[2];
    const expectedResultAlternative = (this.operands[0] + this.operands[1]) * this.operands[2]
    if (providedResult == expectedResult || providedResult == expectedResultAlternative) {
      this.joinButtonDisabled = false;
    } else {
      this.joinButtonDisabled = true;
    }
  }
}
