import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { BaseComponent } from './pages/base/base.component';
import { AssignmentComponent } from './pages/assignment/assignment.component';
import { SuccessComponent } from './pages/success/success.component';
import { RegisterFeedbackComponent } from './pages/register-feedback/register-feedback.component';
import { FailComponent } from './pages/fail/fail.component';
import { LoginGuardService } from './services/login-guard.service';
import { AssignmentStepComponent } from './pages/assignment-step/assignment-step.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'landing-page',
    pathMatch: 'full'
  },
  {
    path: 'landing-page',
    component: LandingPageComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'assignment',
    component: AssignmentComponent
  },
  {
    path: 'assignment-second',
    component: AssignmentStepComponent
  },
  {
    path: 'success',
    component: SuccessComponent
  },
  {
    path: 'feedback',
    component: RegisterFeedbackComponent
  },
  {
    path: 'fail',
    component: FailComponent
  },
  {
    path: ':code',
    component: BaseComponent,
    children: [
      {
        path: 'landing-page',
        component: LandingPageComponent
      },
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'register',
        component: RegisterComponent
      },
      {
        path: 'assignment',
        component: AssignmentComponent
      },
      {
        path: 'assignment-second',
        component: AssignmentStepComponent
      },
      {
        path: 'success',
        component: SuccessComponent
      },
      {
        path: 'feedback',
        component: RegisterFeedbackComponent
      },
      {
        path: 'fail',
        component: FailComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
