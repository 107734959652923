import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable()
export class CouponService {
  constructor(private http: HttpClient) { }

  postCoupon(campaignId: number, data: any): Promise<void> {
    // tslint:disable-next-line: max-line-length
    return this.http.post<void>(environment.apiName + '/campaigns/' + campaignId + '/coupon'  , data, { headers: this.getRequestHeaders() }).toPromise();
  }

  getCoupons(campaignId: number): Promise<void> {
    // tslint:disable-next-line: max-line-length
    return this.http.get<void>(environment.apiName + '/campaigns/' + campaignId + '/coupon' , { headers: this.getRequestHeaders() }).toPromise();
  }


  getRequestHeaders() {
    return new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    );
  }

}
