import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingPageComponent, SafePipe } from './pages/landing-page/landing-page.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { TopBarComponent } from './pages/top-bar/top-bar.component';
import { BaseComponent } from './pages/base/base.component';
import { TranslateModule, TranslateService, TranslateStore, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UserDataService } from './services/user-data.service';
import { AssignmentComponent } from './pages/assignment/assignment.component';
import { SuccessComponent } from './pages/success/success.component';
import { BotDetectCaptchaModule } from 'angular-captcha';
import { RegisterFeedbackComponent } from './pages/register-feedback/register-feedback.component';
import { FailComponent } from './pages/fail/fail.component';
import { UserService } from './services/user.service';
import { CouponService } from './services/coupon.service';
import { LoginGuardService } from './services/login-guard.service';
import {FormsModule} from '@angular/forms';
import { Utils } from './services/utils.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CampaignService } from './services/campaign.service';
import { EventsService } from './services/events.service';
import { AssignmentStepComponent } from './pages/assignment-step/assignment-step.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// i18n support
export function createTranslateLoader(http: HttpClient, baseHref) {
  // Temporary Azure hack
  if (baseHref == null && typeof window !== 'undefined') {
    baseHref = window.location.origin;
  }
  if (baseHref == null) {
    baseHref = '';
  }
  // i18n files are in `wwwroot/assets/`
  return new TranslateHttpLoader(http, `${baseHref}/assets/i18n/`, '.json');
}


@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    LoginComponent,
    RegisterComponent,
    TopBarComponent,
    BaseComponent,
    AssignmentComponent,
    SuccessComponent,
    RegisterFeedbackComponent,
    FailComponent,
    AssignmentStepComponent,
    SafePipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgxSpinnerModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BotDetectCaptchaModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    NgbModule,
  ],
  providers: [TranslateModule, UserDataService, CampaignService,
    Utils, UserService, CouponService, LoginGuardService, EventsService],
  bootstrap: [AppComponent]
})
export class AppModule { }
