import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { RegisterRequest } from 'src/app/models/registerRequest';
import { UserService } from 'src/app/services/user.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { CampaignService } from 'src/app/services/campaign.service';
import { EventsService } from 'src/app/services/events.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isGeneratedFile } from '@angular/compiler/src/aot/util';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  // tslint:disable-next-line: max-line-length
  constructor(private router: Router, private userService: UserService, private route: ActivatedRoute, private campaignService: CampaignService,
              private spinner: NgxSpinnerService, private userDataService: UserDataService, private eventsService: EventsService,
              public translate: TranslateService, private modalService: NgbModal) { }
  clickFirst = false;
  clickSecond = false;
  clickThird = false;
  captchaComponent: any = {};
  email?: string;
  confirmEmail?: string;
  password?: string;
  confirmPassword?: string;
  firstName?: string;
  lastName?: string;
  code: any;
  campaign: any;
  selectedCountry: any;
  countries;

  error = false;
  errorEmail = false;
  emailConfirmError = false;
  checkEmail = false;
  checkEmailConfirm = false;
  @ViewChild('registerErrorModal') templateRef: TemplateRef<any>;
  @ViewChild('userEmail') emailInput;
  firstNameError = false;
  lastNameError = false;
  passwordLengthError = false;

  ngOnInit(): void {
    this.eventsService.langString.subscribe(langNow => {
      this.translate.use(langNow);
    });
    this.eventsService.campaignCode.subscribe(codeRequest => this.code = codeRequest);
/*     this.route.queryParams.subscribe(params => {
      this.code = params.code;
      if (this.code === 0) {
        this.code = '';
      } */
    if (this.code === 0) {
        this.code = '';
      }
    this.campaignService.getCampaignUrl(this.code).then((response: any) => {
        console.log(response);
        this.campaign = response;
        this.spinner.hide();
      }).catch(error => {
       console.log(error);
       this.spinner.hide();
      });
/*   }); */
    /* this.captchaComponent.captchaEndpoint =
      'https://your-app-backend-hostname.your-domain.com/simple-captcha-endpoint'; */

      this.userService.getCountries().then(res => {
        this.countries = res['records'];
        this.selectedCountry = this.countries[0]['code'];
      });
  }

  clickCheckbox1() {
    if (this.clickFirst === false) {
      this.clickFirst = true;
    } else {
      this.clickFirst = false;
    }
  }

  clickCheckbox2() {
    if (this.clickSecond === false) {
      this.clickSecond = true;
    } else {
      this.clickSecond = false;
    }
  }


  clickCheckbox3() {
    if (this.clickThird === false) {
      this.clickThird = true;
    } else {
      this.clickThird = false;
    }
  }

  createAccount() {
    this.firstNameError = false;
    this.lastNameError = false;
    this.passwordLengthError = false;
    const emailErrors = this.emailInput?.errors
    if (emailErrors && emailErrors.email) {
      // invalid email
      return;
    }
    if (!this.firstName) {
      this.firstNameError = true;
    }
    if (!this.lastName) {
      this.lastNameError = true;
    }
    if (!this.password || this.password.length < 8) {
      this.passwordLengthError = true;
    }
    if (this.firstNameError || this.lastNameError || this.passwordLengthError) {
      return;
    }

    if (this.password === this.confirmPassword && this.email === this.confirmEmail) {
      this.error = false;
      this.emailConfirmError = false;
      if (this.email.includes('@')) {
        this.spinner.show();
        this.errorEmail = false;
        const userRegister: RegisterRequest = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          password: this.password,
          news: this.clickThird,
          country: this.selectedCountry
        };
        console.log(userRegister);
        this.userService.registerUser(userRegister).then(response => {
          console.log(response);
          localStorage.setItem('token', response.accessToken);
          this.userDataService.checkUser().then(user => {
            console.log(user);
            if (user !== null) {
              this.router.navigate([this.code + '/feedback']);
            } else {
              this.spinner.hide();
            }
          }).catch(error => {
            this.spinner.hide();
            console.log(error);
            return null;
          });
        }).catch(error => {
          this.spinner.hide();
          console.log(error);
          this.openRegisterErrorModal();
          return null;
        });
      } else {
        this.errorEmail = true;
      }

    } else {
      if(this.password != this.confirmPassword){
        this.error = true;
        this.emailConfirmError = false;
      }
      if(this.email != this.confirmEmail){
        this.emailConfirmError = true;
        this.error = false;
      }
    }
  }

  openRegisterErrorModal() {
    this.modalService.open(this.templateRef, {ariaLabelledBy: 'modal-basic-title'});
  }

  insertEmail(){
    let tmp = this.email.substring(this.email.indexOf("@"), this.email.length );
    if(!tmp.includes(".") || tmp.substring(tmp.indexOf("."), tmp.length ).length == 1){
      this.checkEmail = true;
    }else{
      this.checkEmail = false;
    }
  }

  insertConfirmEmail(){
    let tmp = this.confirmEmail.substring(this.confirmEmail.indexOf("@"), this.confirmEmail.length );
    if(!tmp.includes(".") || tmp.substring(tmp.indexOf("."), tmp.length ).length == 1){
      this.checkEmailConfirm = true;
    }else{
      this.checkEmailConfirm = false;
    }
  }
}
