import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class EventsService {

  private campaignCodeEvent = new BehaviorSubject<number>(0);
  private langEvent = new BehaviorSubject<string>(null);
  public setCampaignCode(code: number) {
    this.campaignCodeEvent.next(code);
  }
  public setLanguage(lang: string) {
    this.langEvent.next(lang);
  }

  public get campaignCode(): Observable<number> {
    return this.campaignCodeEvent.asObservable();
  }
  public get langString(): Observable<string> {
    return this.langEvent.asObservable();
  }

}
