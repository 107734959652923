import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { EventsService } from 'src/app/services/events.service';
import { CampaignService } from 'src/app/services/campaign.service';
import { Campaign } from 'src/app/models/campaign';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-register-feedback',
  templateUrl: './register-feedback.component.html',
  styleUrls: ['./register-feedback.component.scss']
})
export class RegisterFeedbackComponent implements OnInit {

  code: any;
  campaign: Campaign;
  needsActivation = false;
  afterLogin = false;

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private eventsService: EventsService,
    private campaignService: CampaignService,
    private userService: UserService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.eventsService.campaignCode.subscribe(codeRequest => this.code = codeRequest);
    if (this.code === 0) {
        this.code = '';
      }
    this.spinner.show();
    this.route.queryParams.subscribe(map => {
      console.log(map);
      if (map['afterLogin']) {
        this.afterLogin = true;
      }
    });
    this.userService.getUser().then( (user: User) => {
      this.needsActivation = !!(user.emailActivationTermDate);
      return this.campaignService.getCampaignUrl(this.code)
    }).then((response: any) => {
      this.campaign = response;
      this.spinner.hide();
      if (!this.needsActivation) {
        // this.router.navigate([this.code + '/assignment']);
      }
    }).catch(error => {
      console.log(error);
      this.spinner.hide();
    });
  }

  associate() {
    this.spinner.show();
    // this.router.navigate([this.code + '/assignment']);
  }

  resendActivationEmail() {
    this.spinner.show();
    this.userService.resendActivationEmail().then(response => {
      this.spinner.hide();
    });
  }
}
