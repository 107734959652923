import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CouponService } from '../../services/coupon.service';
import { Utils } from '../../services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocationStrategy } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { EventsService } from '../../services/events.service';
import { CampaignService } from 'src/app/services/campaign.service';

@Component({
  selector: 'app-assignment-step',
  templateUrl: './assignment-step.component.html',
  styleUrls: ['./assignment-step.component.scss']
})
export class AssignmentStepComponent implements OnInit {
  trainersJSON: any;
  codePath: any;
  campaign: any;
  trainerCode: any;
  data: {};
  serialCode: any;
  langUsed: string;
  enabledTrainerCodes: Array<number> = [];

  // tslint:disable-next-line: max-line-length
  constructor(private router: Router, private utils: Utils,  private translate: TranslateService,
              private spinner: NgxSpinnerService, private route: ActivatedRoute, private campaignService: CampaignService,
              private couponService: CouponService, private eventsService: EventsService) { }

  ngOnInit(): void {
    this.campaignService.serial.subscribe(text => this.serialCode = text);

    this.spinner.show();

    this.eventsService.langString.subscribe(langNow => {
      this.translate.use(langNow);
    });

    this.eventsService.campaignCode.subscribe(codeRequest => this.codePath = codeRequest);
    if (this.codePath === 0) {
      this.codePath = '';
    }
    this.campaignService.getCampaignUrl(this.codePath).then((response: any) => {
        this.campaign = response;
        this.spinner.hide();
      }).then(res => {
        let campaignId = this.campaign.id;
        this.campaignService.getModels(campaignId).then(res2 => {
          for (let r of res2) {
            this.enabledTrainerCodes.push(r['trainerCode']);
          }
        }, err => {});
      }).then(res => {
          return this.utils.getTrainers();
      }).then(res => {
        let trainers: Array<any> = res.trainers;
        if (this.enabledTrainerCodes.length > 0) {
          this.trainersJSON = trainers.filter(t => {
            return this.enabledTrainerCodes.find(c => c == t['code']) !== undefined;
          });
        } else {
          // fallback to all trainers
          this.trainersJSON = trainers;
        }
       
        this.spinner.hide();
      })
      .catch(error => {
       console.log(error);
       this.spinner.hide();
    });

    if (localStorage.getItem('token') === null) {
      this.router.navigate(['/login']);
    }
  }

  checkModel() {
    this.eventsService.langString.subscribe(langNow => this.translate.use(langNow));
    this.spinner.show();
    this.campaignService.checkModel(this.campaign.id, this.trainerCode).then((response: any) => {
      if (response === true) {
        this.associate();
      } else {
        let error = response['error'] || {}
        this.router.navigate([this.codePath + '/fail'], {queryParams: {error: error['code']}});
      }
    }).catch(response => {
      //console.log(response);
      this.eventsService.setLanguage(this.langUsed);
      let error = response['error'];
      this.router.navigate([this.codePath + '/fail'], {queryParams: {error: error['code']}});
    });
  }

  associate() {
    this.spinner.show();
    this.eventsService.langString.subscribe(langNow => {
      this.langUsed = langNow;
    });
    this.data = {
      serialCode:  this.serialCode,
      trainerCode: this.trainerCode,
      lang: this.langUsed,
    };
    console.log(this.data);
    this.couponService.postCoupon(this.campaign.id, this.data).then((response: any) => {
      // this.checkReminderElite();
      this.eventsService.setLanguage(this.langUsed);
      this.router.navigate([this.codePath + '/success'], {state: response});
    }).catch(response => {
      // console.log(response);
      this.eventsService.setLanguage(this.langUsed);
      let error = response['error'];
      this.router.navigate([this.codePath + '/fail'], {queryParams: {error: error['code']}});
    });

  }

  checkReminderElite() {
    this.couponService.getCoupons(this.campaign.id).then((response: any) => {
      console.log('ok');
      console.log(response);
    }).catch(error => {
      console.log('no');
      console.log(error);
    });
  }


}
