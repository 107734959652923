import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserDataService } from 'src/app/services/user-data.service';
import { UserService } from 'src/app/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { EventsService } from 'src/app/services/events.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {
  constructor(private userDataService: UserDataService, private userservice: UserService, private spinner: NgxSpinnerService,
              // tslint:disable-next-line: max-line-length
              private router: Router, private route: ActivatedRoute, private eventsService: EventsService, private translate: TranslateService
            ) { }

  @ViewChild('menu') menuElement: ElementRef;
  user: any;
  public isMenuActive = false;
  public firstName = '';
  public lastName = '';
  public userId = 0;
  public userEmail: string;
  public imgUrl = '';
  // tslint:disable-next-line: variable-name
  public configs_number: number;

  // tslint:disable-next-line: variable-name
  lang_click = false;
  lang: string;
  bikeImage: any;

  noIMG = true;
  codePath: any;
  langUsed: string;
  ngOnInit(): void {
   /* const lang = navigator.language.substr(0, 2);
   this.translate.use(lang);
   this.eventsService.setLanguage(lang);
   this.langUsed = lang; */
    this.eventsService.langString.subscribe(langNow => {
      this.langUsed = langNow;
    });
    this.eventsService.setLanguage( this.langUsed);
    if (this.langUsed === 'en') {
      this.lang = 'English';
    } else {
      this.lang = 'Italiano';
    }
    this.eventsService.campaignCode.subscribe(codeRequest => this.codePath = codeRequest);

    this.userDataService.checkUser().then(user => {
      this.firstName = user.firstName;
      this.lastName = user.lastName;
      this.imgUrl = 'https://s3-eu-west-1.amazonaws.com/' + environment.s3PublicBucket + '/' + user.uid + '/userimage.jpg';
      this.userId = user.id;
      this.userEmail = user.email
    }).catch(error => {
      this.firstName = '';
      this.lastName = '';
      this.userId = 0;
      this.userEmail = undefined;
    });

  }
  profileClicked() {
    this.lang_click = false;
    console.log(this.menuElement.nativeElement);
    if (this.menuElement.nativeElement.classList.contains('is-active')) {
      this.isMenuActive = false;
    } else {
      this.isMenuActive = true;
    }
  }
  languageClicked() {
    this.lang_click = true;
    console.log(this.menuElement.nativeElement);

    if (this.menuElement.nativeElement.classList.contains('is-active')) {
      this.isMenuActive = false;
    } else {
      this.isMenuActive = true;
    }
  }

  loginClicked() {
    this.spinner.show();
    console.log(this.codePath);
    if (this.codePath) {
      this.router.navigate([this.codePath + '/login']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  registerClicked() {
    this.spinner.show();
    console.log(this.codePath);
    if (this.codePath) {
      this.router.navigate([this.codePath + '/register']);
    } else {
      this.router.navigate(['/register']);
    }
  }

  logoutClicked() {
    this.eventsService.campaignCode.subscribe(codeRequest => this.codePath = codeRequest);
    this.userDataService.logout();
    this.firstName = '';
    this.lastName = '';
    this.userId = 0;
    this.userEmail = undefined;
    localStorage.removeItem('token');
    if (this.codePath === 0) {
      this.codePath = '';
    }
    this.router.navigate([this.codePath + '/login']);
  }

  langClicked(lang: string) {
    if (lang === 'en') {
      this.lang = 'English';
    } else {
      this.lang = 'Italiano';
    }
    this.translate.use(lang);
    this.isMenuActive = false;
    this.eventsService.setLanguage(lang);
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.menuElement.nativeElement.contains(event.target) && this.menuElement.nativeElement.classList.contains('is-active')) {
      this.isMenuActive = false;
    }
  }

  updateUrl() {
    this.noIMG = false;
  }


/*   goToConfigurations() {
    this.eventService.showSpinner();
    this.router.navigate(['/configurations']);
  }
 */
}
