import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

export interface ResponseGet {
  items?: Array<any>;
  totalPages?: number;
}
@Injectable()
export class Utils {
  constructor(private http: HttpClient) { }

  getTrainers(): Promise<any> {
    return this.http.get<any>(environment.apiName + '/trainers' ,
    { headers: this.getRequestHeaders()}).toPromise();
  }

  getRequestHeaders() {
    return new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
    );
  }

}
