import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { AuthRequest } from 'src/app/models/authRequest';
import { UserDataService } from 'src/app/services/user-data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CampaignService } from '../../services/campaign.service';
import { Campaign } from '../../models/campaign';
import { EventsService } from '../../services/events.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private router: Router,
              private route: ActivatedRoute,
              private eventsService: EventsService,
              private userService: UserService,
              private spinner: NgxSpinnerService,
              private campaignService: CampaignService,
              private userDataService: UserDataService,
              public translate: TranslateService,
              private modalService: NgbModal
              ) { }

  email?: string;
  password?: string;
  error = false;
  code: any;
  campaign: Campaign;
  langUsed: string;
  resetPasswordEmail: string;
  resetPasswordModal: any;
  resetPasswordEmailError: string;
  resetPasswordSuccess = false;

  ngOnInit(): void {
    this.eventsService.langString.subscribe(langNow => {
      this.translate.use(langNow);
      this.langUsed = langNow;
    });
    this.eventsService.campaignCode.subscribe(codeRequest => this.code = codeRequest);
  /*   this.route.paramMap.subscribe(params => {
      console.log(params);
      this.code = +params.get('code');
      console.log(this.code); */
    if (this.code === 0) {
        this.code = '';
      }
    this.campaignService.getCampaignUrl(this.code).then((response: any) => {
        this.campaign = response;
        this.spinner.hide();
      }).catch(error => {
       console.log(error);
       this.spinner.hide();
      });
/*   }); */
  }

  login() {
    this.spinner.show();
    if (!this.email || !this.password) {
      this.spinner.hide();
      return;
    }

    const auth: AuthRequest = {
      email: this.email,
      password: this.password
    };
    this.userService.auth(auth).then(token => {
      console.log(token);
      this.error = false;
      localStorage.setItem('token', token.accessToken);
      this.userDataService.checkUser().then(user => {
        if (user !== null) {
          console.log(this.code);
          const needsActivation = !!(user.emailActivationTermDate);
          if (needsActivation) {
            this.router.navigate([this.code + '/feedback'], {queryParams: {afterLogin: true}});
          } else {
            this.router.navigate([this.code + '/assignment']);
          }
        } else {
          this.spinner.hide();
        }
      }).catch(error => {
        this.spinner.hide();
        console.log(error);

        return null;
      });
    }).catch(error => {
      this.spinner.hide();
      console.log(error);
      this.error = true;
      return null;
    });
  }

  goToRegisterPage() {
    console.log('entra in register');
    this.spinner.show();
    this.router.navigate([this.code + '/register']);
  }

  openForgotPasswordModal(content) {
    this.resetPasswordEmail = undefined;
    this.resetPasswordSuccess = false;
    this.resetPasswordEmailError = '';
    this.resetPasswordModal = this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
  }

  closeForgotEmailModal() {
    if (this.resetPasswordSuccess) {
      this.resetPasswordModal.close();
      return;
    }
    this.resetPasswordEmailError = '';
    let email = this.resetPasswordEmail;
    const EMAIL_REGEXP = new RegExp(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)
    if (!EMAIL_REGEXP.test(email)) {
      this.resetPasswordEmailError = 'Invalid email!';
      return
    }

    this.spinner.show();
    this.userService.resetPassword(email).then(res => {
      this.spinner.hide();
      this.resetPasswordSuccess = true;
    }).catch(error => {
      this.spinner.hide();
      console.log(error);
      this.resetPasswordEmailError = this.translate.instant('message_incorrect_email');
    });
  }
}
