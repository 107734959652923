import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRoute, ParamMap, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from './user.service';
import {LocationStrategy} from '@angular/common';
import { EventsService } from './events.service';

@Injectable()
export class LoginGuardService implements CanActivate {
  constructor(private eventsService: EventsService,
              // tslint:disable-next-line: max-line-length
              private router: Router, private route: ActivatedRoute, private userService: UserService
  ) {
  }

  code: any;
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log('entra');
    this.code = state.url.split('/')[1];
    if (this.code === 'landing-page' || this.code === 0) {
      this.code = '';
    }
    return this.userService.getUser().then(user => {
      console.log('ok');
    /*   this.eventsService.setCampaignCode(this.code); */
      this.route.paramMap.subscribe(params => {
        this.eventsService.setCampaignCode(this.code);
        console.log(this.code);
    });
      return true;
    }).catch(error => {
      console.log('vado al login');
      this.router.navigate(['/login'], { queryParams: { code: this.code } });
      return false;
    });
  }
}
