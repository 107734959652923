import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocationStrategy } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { EventsService } from '../../services/events.service';
import { CampaignService } from 'src/app/services/campaign.service';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-fail',
  templateUrl: './fail.component.html',
  styleUrls: ['./fail.component.scss']
})
export class FailComponent implements OnInit {
  code: any;
  campaign: any;
  codePath: any;
  email: string;
  errorCode: number = undefined;
  errorAdditionalMessage = "";
  // tslint:disable-next-line: max-line-length
  constructor(private router: Router, private translate: TranslateService, private eventsService: EventsService, private campaignService: CampaignService,
              private spinner: NgxSpinnerService, private locationStrategy: LocationStrategy, private route: ActivatedRoute, private userService: UserService) { }

  ngOnInit(): void {
    this.eventsService.langString.subscribe(langNow => this.translate.use(langNow));
    this.eventsService.campaignCode.subscribe(codeRequest => this.codePath = codeRequest);
    if (this.codePath === 0) {
      this.codePath = '';
    }

    this.userService.getUser().then(user => {
      this.email = user.email;
    }).then(res => {
      this.route.queryParams.subscribe(params => {
        let errorCode = +params['error'];
        if (errorCode > 0 && Number.isFinite(errorCode)) {
          this.errorCode = errorCode;
          if (errorCode == 1007) {
            // messaggio specifico in caso di attivazioni multiple per utenti "smart"
            this.errorAdditionalMessage = this.translate.instant('fail_1007', {'email': this.email});
          } else if (errorCode == 1009) {
            // riprova più tardi per controllo Ucraina 2022
            this.errorAdditionalMessage = this.translate.instant('fail_1009');
          } else if (errorCode == 1012) {
            this.errorAdditionalMessage = this.translate.instant('fail_1012', {'email': this.email});
          } else {
            this.errorAdditionalMessage = "";
          }
        }
      });
    })

    this.campaignService.getCampaignUrl(this.codePath).then((response: any) => {
        this.campaign = response;
        this.spinner.hide();        
      }).catch(error => {
       console.log(error);
       this.spinner.hide();
    });
  }

  goBackAssociate() {
    this.spinner.show();
    this.router.navigate([this.codePath + '/assignment']);
  }

  /*
   * Mapping codici di errore:
   * 
   * E1001: errore in chiamata video
   * E1002: errore nel consumo del coupon
   * E1003: errore in creazione coupon item LSABBMET
   * E1004: seriale non valido
   * E1005: seriale già utilizzato
   * E1006: seriale o regex non corretti per modello
   * E1007: attivazione multipla in meno di 15 giorni e flag canActivateMultipleCoupons non attivo
   * E1008: errore validazione token reCAPTCHA
   * E1009: seriali vicini (±10) attivati negli ultimi 3 minuti (controllo "Ucraina 2022")
   * E1010: dominio bloccato (controllo "Ucraina 2022")
   * E1011: nazione bloccata (controllo "Ucraina 2022")
   * E1012: utente con email non attivata (blocco "Aprile 2022")
   * E1013: seriale già prenotato in pendingSubscriptions
   * E1014: CRC seriale non valido
   * E1015: blocco su indirizzo IP (settembre 2022)
   */
}
