import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import {LocationStrategy} from '@angular/common';
import { CampaignService } from 'src/app/services/campaign.service';
import { Campaign } from '../../models/campaign';
import { EventsService } from 'src/app/services/events.service';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../../services/user.service';
import { typeWithParameters } from '@angular/compiler/src/render3/util';


// tslint:disable-next-line: use-pipe-transform-interface
@Pipe({ name: 'safeHtml'})
export class SafePipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) {}

  transform(value: string) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  // tslint:disable-next-line: max-line-length
  constructor(private router: Router, public translate: TranslateService,
              private route: ActivatedRoute, private userService: UserService, private campaignService: CampaignService,
              private spinner: NgxSpinnerService, private eventsService: EventsService) { }
  code: any;
  campaign: Campaign;
  coverImageUrl: any;
  title: any;
  subtitle: any;
  // tslint:disable-next-line: variable-name
  subtitle_superior: any;
  // tslint:disable-next-line: variable-name
  subtitle_inferior: any;
  langUsed: string;

  ngOnInit(): void {
/*     const lang = navigator.language.substr(0, 2);
    this.translate.use(lang);
    this.langUsed = lang; */
    this.eventsService.langString.subscribe(langNow => {
      this.langUsed = langNow;
      if (this.langUsed == null) {
        this.langUsed = 'it';
      }
    });
    this.eventsService.campaignCode.subscribe(codeRequest => this.code = codeRequest);
    //console.log(this.code);
    if (this.code === 0) {
      this.code = '';
    }
    this.campaignService.getCampaignUrl(this.code).then((response: any) => {
        this.campaign = response;
        this.spinner.hide();
      }).catch(error => {
       console.log(error);
       this.spinner.hide();
    });
/*     this.eventsService.langString.subscribe(langNow => {
      console.log(langNow);
      this.langUsed = lang;
    }); */
  }

  goToLogin() {
    this.spinner.show();
    this.router.navigate(['/register']);
  }
  goToAssignment() {
    this.spinner.show();
/*     this.eventsService.langString.subscribe(langNow => {
      this.langUsed = langNow;
    }); */
    this.userService.getUser().then(user => {
      this.eventsService.setCampaignCode(this.code);
      this.eventsService.setLanguage(this.langUsed);
      console.log(this.langUsed);
      this.router.navigate([this.code + '/assignment']);
    }).catch(error => {
      this.eventsService.setCampaignCode(this.code);
      this.eventsService.setLanguage(this.langUsed);
      console.log('vado al login');
      console.log(this.langUsed);
      this.router.navigate([this.code + '/login']);
    });
  }

}
