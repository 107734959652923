import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User } from '../models/user';
import { AuthRequest } from '../models/authRequest';
import { Token } from '../models/token';
import { RegisterRequest } from '../models/registerRequest';

@Injectable()
export class UserService {

  constructor(private http: HttpClient) { }

  getUser(): Promise<User> {
    return this.http.get<User>(environment.apiName + '/users/me', { headers: this.getRequestHeaders() }).toPromise();
  }

  auth(body: AuthRequest): Promise<Token> {
    return this.http.post<Token>(environment.apiName + '/auth', body).toPromise();
  }

  registerUser(body: RegisterRequest): Promise<any> {
    return this.http.post<Token>(environment.apiName + '/register', body).toPromise();
  }

  getRequestHeaders() {
    return new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    );
  }

  getCountries(): Promise<any> {
    return this.http.get(environment.apiName + '/countries', {headers: this.getRequestHeaders()}).toPromise();
  }

  resetPassword(email: string): Promise<any> {
    return this.http.post<Token>(environment.apiName + '/user/recover', {email: email}).toPromise();
  }

  resendActivationEmail(): Promise<any> {
    const headers = this.getRequestHeaders();
    return this.http.post<any>(environment.apiName + '/user/resend-activation-email', {}, { headers: headers }).toPromise();
  }
}
