import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CampaignService {

  constructor(private http: HttpClient) { }

  private serialCode = new BehaviorSubject<string>('');
  serial = this.serialCode.asObservable();

  setSerial(text: string) {
    this.serialCode.next(text);
  }

  getCampaign(id: number): Promise<any> {
    return this.http.get<any>(environment.apiName + '/campaigns/' + id, { headers: this.getRequestHeaders() }).toPromise();
  }

  getCampaignUrl(code: string): Promise<any> {
    // tslint:disable-next-line: max-line-length
    return this.http.get<any>(environment.apiName + '/campaigns/url', { headers: this.getRequestHeaders(), params: {url: code}}).toPromise();
  }

  checkSerial(id: number, serial: string): Promise<any> {
    // tslint:disable-next-line: max-line-length
    return this.http.post<any>(environment.apiName + '/campaigns/' + id + '/check-serial', serial , { headers: this.getRequestHeaders()}).toPromise();
  }

  checkModel(id: number, model: string): Promise<any> {
    // tslint:disable-next-line: max-line-length
    return this.http.post<any>(environment.apiName + '/campaigns/' + id + '/check-model', model, { headers: this.getRequestHeaders()}).toPromise();
  }

  getModels(id: number): Promise<any> {
    return this.http.get<any>(environment.apiName + '/campaigns/' + id + '/models', { headers: this.getRequestHeaders()}).toPromise();
  }

  getRequestHeaders() {
    if (localStorage.getItem('token') !== null) {
      return new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    );
    } else {
      return new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
    );
    }
  }

}
