import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { EventsService } from './services/events.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'coupon-frontend';

  constructor(private router: Router,
              public translate: TranslateService,
              private spinner: NgxSpinnerService,
              private eventService: EventsService
  ) {
    translate.setDefaultLang('en');
    const lang = navigator.language.substr(0, 2);
    translate.use(lang);
    this.eventService.setLanguage(lang);
  }
}
