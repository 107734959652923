import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { User } from '../models/user';


@Injectable()
export class UserDataService {

  constructor(private userService: UserService) { }

  private user?: User;

  public checkUser(): Promise<User> {
    const token = localStorage.getItem('token');
    if (token == null) {
      return Promise.resolve(null);
    }

    if (this.user) {
      return Promise.resolve(this.user);
    }
    return this.userService.getUser().then(user => {
      return user;
    }).catch(error => {
      // tslint:disable-next-line: triple-equals
      if (error.status == 401) {
        localStorage.removeItem('token');
      }
      return null;
    });
  }

  public logout() {
    this.user = null;
    localStorage.removeItem('token');
  }

}
